import React, { useState, useEffect, useCallback } from "react"
import { graphql } from "gatsby"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"

// @Material UI
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"

// @Local Components
import Layout from "../components/layout"
import SEO from "../components/seo"

import { LikeButton, FavButton } from "../components/actions/Actions"

const TemplateGallery = ({ data }) => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const [photos, setPhotos] = useState([])
  let { title, images, strapiId } = data && data.strapiGallery ? data.strapiGallery : {}

  useEffect(() => {
    var currentPhotos = []
    images.map((img, index) => {
      currentPhotos.push({
        src: img.url,
        width: 1,
        height: 1,
      })
    })
    setPhotos(currentPhotos)
    return true
  }, [images])

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  if (!data) {
    return (
      <Paper>
        <h2>...</h2>
      </Paper>
    )
  }

  return (
    <Layout section="Multimedia" sectionLink={"/app/multimedia"}>
      <SEO title={title} />
      <Grid
        container
        justify="center"
        className="page-detail multimedia-detail"
      >
        <Typography
          className="page-title"
          variant="h1"
          color="textPrimary"
          align="center"
        >
          {title}
        </Typography>
        <Gallery photos={photos} onClick={openLightbox} />
        <div className="page-actions">
          <LikeButton strapiId={strapiId} type={"Gallery"} title={title} />
          <FavButton strapiId={strapiId} type={"Gallery"} title={title} />
        </div>
      </Grid>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Layout>
  )
}

export const query = graphql`
  query GalleryEventQuery($id: String!) {
    strapiGallery(id: { eq: $id }) {
      id
      strapiId
      title
      images {
        url
        formats {
          medium {
            publicURL
          }
        }
      }
      fields {
        slug
      }
    }
  }
`

export default TemplateGallery
